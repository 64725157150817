
import {computed, defineComponent, ref} from "vue";
import {
    IonContent,
    IonPage,
    IonLabel,
    IonItem
} from "@ionic/vue";
import {execute} from "@/mixins/LoadingMixin";
import FixedFooter from "@/components/FixedFooter.vue";
import {useStore} from "vuex";
import {FooterButtonsBuilder} from "@/mixins/FooterButtonsBuilder";
import router from "@/router";
import DirectionIndicator from "@/components/DirectionIndicator.vue";
import {Partner} from "@/models/PartnerModel";
import {getPartnersWithAdmins} from "@/services/PartnerService";

export default defineComponent({
    name: "Partners",
    components: {
        DirectionIndicator,
        IonContent,
        IonPage,
        IonLabel,
        IonItem,
        FixedFooter
    },
    async ionViewWillEnter() {
        await this.$store.dispatch('app/setPageTitle', this.language.partners);
        await execute(async () => {
            this.partners = await getPartnersWithAdmins();
        }, this.language.errors.gettingPartners);
    },
    setup() {
        const store = useStore();
        const language = computed(() => store.getters['app/language']);
        const partners = ref([] as Partner[]);
        
        const editPartner = (id: string) => {
            router.push({ name: 'editPartner', params: { id: id }});
        }
        
        return {
            language,
            partners,
            editPartner,
            footerButtons: computed(() => 
                new FooterButtonsBuilder()
                .addClearButton(false, () => router.push({ name: 'addPartner' }), language.value.addPartner)
                .create()
            )
        }
    }
});
